import React, { useContext } from 'react';
import * as language from '../constants/languages';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import { AuthContext } from '../pages/RootPage';
import '../styles/SearchHeader.css'
class SearchHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchedProductName: this.props.value,
            whatsAppNo: ""
        };

    }

    componentDidMount() {
        this.getCompanyInfo();
    }

    //get sotre name
    async getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            this.setState({
                whatsAppNo: ""
            })
        } else {
            this.companyInfo = JSON.parse(value);
            this.setState({
                whatsAppNo: this.companyInfo.whatsAppNo
            })
        }

    }


    async openWhatsapp() {
        window.opem('whatsapp://send?phone=' + this.state.whatsAppNo)
    }

    // search product
    async searchProduct() {
        this.props.searchName(this.state.searchedProductName)
    }
     keydown(e) {
        if (e.key === "Enter") {
            this.searchProduct()
        }
    }

    render() {
        return (
            <div style={{ display: 'flex',marginBlock:30, marginInline:15, alignItems:'center' }}>
                <div style={{ justifyContent: 'center' }} onClick={() => this.props.toggleDrawer()}>
                    <img src={DrawerIcon} style={{ width: 20, height: 20 }} />
                </div>
                <div style={{ display: 'flex',  marginLeft:15, flex:1}} className='SearchPageField'>
                    <input
                        placeholder={language.searchLabel}
                        style={{ fontFamily: 'Poppins-Regular', border: 'none', padding: 10,width:'100%', borderRadius:17, outline:'none' }}
                        autoCapitalize={'none'}
                        autoFocus={true}
                        onChange={(event) => this.setState({ searchedProductName: event.target.value })}
                        // onSubmitEditing={() => this.searchProduct()}
                        onSubmit={() => this.searchProduct()}
                        defaultValue={this.props.value}
                        inputMode='search'
                        onKeyDown={(e)=>this.keydown(e)}
                    />
                    <div style={{position:'absolute',right:40, display:'flex', alignItems:'center', justifyContent:'center', top:35}} onClick={() => this.searchProduct()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="28px" height="28px" fill="#000"><path d="M 20.5 6 C 12.515556 6 6 12.515562 6 20.5 C 6 28.484438 12.515556 35 20.5 35 C 23.773158 35 26.788919 33.893018 29.220703 32.050781 L 38.585938 41.414062 A 2.0002 2.0002 0 1 0 41.414062 38.585938 L 32.050781 29.220703 C 33.893017 26.788918 35 23.773156 35 20.5 C 35 12.515562 28.484444 6 20.5 6 z M 20.5 10 C 26.322685 10 31 14.677319 31 20.5 C 31 23.295711 29.914065 25.820601 28.148438 27.697266 A 2.0002 2.0002 0 0 0 27.701172 28.144531 C 25.824103 29.912403 23.29771 31 20.5 31 C 14.677315 31 10 26.322681 10 20.5 C 10 14.677319 14.677315 10 20.5 10 z"  />
                        </svg>
                    </div>
                </div>

            </div>

        )
    }


}



export default function (props) {
    const { toggleDrawer } = useContext(AuthContext);
    return <SearchHeader {...props} toggleDrawer={toggleDrawer} />;
}