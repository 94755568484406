import { PureComponent, Component, } from 'react';
import * as language from '../constants/languages';
import Plus from '../assets/svg/plus.svg'
import Minus from '../assets/svg/minus.svg'
import '../styles/ProductListingComponent.css'
import { useNavigate } from 'react-router-dom';
class ProductsListingComponent extends Component {

    constructor(props) {
        super(props);
        this.branchId = localStorage.getItem('storeId');

        this.state = {
            currencySymbol: '',
            data: [],
            productData: this.props.productData
        }
    }
    async cartQty() {

        try {

            const cart = localStorage.getItem('cartItems')
            if (cart) {
                this.cartItems = JSON.parse(cart);
                for (let i of this.state.productData) {
                    const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == i.productId);
                    if (elementsIndex != -1) {
                        i.cartQty = this.cartItems.customerCartItems[elementsIndex].qty
                    }
                    else {
                        i.cartQty = 0
                    }
                }

                for (let i = 0; i < this.state.productData.length; i++) {
                    if (this.state.productData[i].cartQty > 0) {
                        this.setState({
                            ['quantity_' + (this.state.productData[i].productId)]: this.state.productData[i].cartQty,
                            ['value_' + (this.state.productData[i].productId)]: null,
                        })
                    }
                    else {
                        this.setState({
                            ['quantity_' + (this.state.productData[i].productId)]: 0,
                            ['value_' + (this.state.productData[i].productId)]: null,
                        })
                        this.state.productData[i].cartQty = 0
                    }
                }
            }
            else {

                for (let i = 0; i < this.state.productData.length; i++) {
                    this.setState({
                        ['quantity_' + (this.state.productData[i].productId)]: 0,
                        ['value_' + (this.state.productData[i].productId)]: null,
                    })

                    this.state.productData[i].cartQty = 0

                }
            }
            this.setState({
                data: this.state.productData
            })
        }
        catch (e) { console.log(e, 'error') };
    }

    qtyChangeEvent = (data) => {
        // if (data.token != 0) {
        this.cartQty();
        // }
    }
    // componentDidUpdate(prevProps) {
    //     if (prevProps.productData != this.state.productData) {
    //     console.log('inside')

    //         this.cartQty()
    //     }
    // }

    componentDidMount() {
        this.cartQty()
        this.getCompanyInfo();
        window.addEventListener('productQtyChange', this.qtyChangeEvent)

    }



    componentWillUnmount() {
        window.removeEventListener('productQtyChange', this.qtyChangeEvent);
    }
    updatePage() {
        this.setState({
            productData: this.props.productData
        }, () => {
            this.cartQty()
        })
    }
    componentDidUpdate(prevProps) {
        if (this.state.productData[0]?.productId !== this.props.productData[0]?.productId) {
            this.updatePage()
        }
    }
    //get sotre name
    async getCompanyInfo() {
        const value = localStorage.getItem('companyInfo')
        // console.log(value)
        if (value === null || value === undefined) {
            this.companyInfo = null;
            this.setState({
                currencySymbol: ""
            })
        } else {
            this.companyInfo = JSON.parse(value);
            this.setState({
                currencySymbol: this.companyInfo.currencySymbol
            })
        }
        // console.log(this.state.currencySymbol, 'CurrencySymbol state');


    }
    // quantity select
    async quantityPressed(itemIndex, valueAction, productPrice, productId, productData, productListIndex, e) {
        e.stopPropagation()
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        if (this.state['value_' + itemIndex] == null) {
            if (valueAction === 'increment') {
                // await this.setState({
                //     spinnerOverlay: true,

                // })
                this.cartAddRemove(productId, 1, itemIndex, productData) //add qny to cart

            } else {
                if (this.state['quantity_' + itemIndex] != this.incrementValue) {
                    // await this.setState({
                    //     spinnerOverlay: true,
                    // })
                    this.cartAddRemove(productId, 0, itemIndex, productData) //reduce qny to cart
                } else {
                    this.removeItem(productId, productData, productListIndex);
                }
            }
        }

    }

    // add/remove cart
    cartAddRemove(id, action, itemIndex, productData) {

        this.cartItemsJson = localStorage.getItem('cartItems');

        if (!this.cartItemsJson) {
            this.cartItems = {
                customerCartItems: [
                ],
                branchId: parseInt(this.branchId),
                companyId: language.companyidValue
            }
        } else {
            this.cartItems = JSON.parse(this.cartItemsJson);
        }
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        if (action == 1) { //add to cart

            if (productData.isStockAvailable == true) {

                const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == productData.productId)
                if (this.branchId == this.cartItems.branchId) {
                    var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue);
                    productData.allowNegativeStock = true
                    if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
                        if (elementsIndex == -1) {
                            this.cartItems.customerCartItems = [
                                ...this.cartItems.customerCartItems,
                                {
                                    product: productData,
                                    qty: this.incrementValue,
                                    variantId: null
                                }
                            ]
                        } else {
                            this.cartItems.customerCartItems[elementsIndex] = {
                                product: this.cartItems.customerCartItems[elementsIndex].product,
                                qty: this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue,
                                variantId: this.cartItems.customerCartItems[elementsIndex].variantId
                            }
                        }

                        localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
                        const event = new CustomEvent('productQtyChange');
                        window.dispatchEvent(event)
                        this.setState({
                            ['quantity_' + itemIndex]: this.state['quantity_' + itemIndex] + this.incrementValue,
                            // spinnerOverlay: false
                        })
                        // Toast.show(language.itemAddedTocart);
                    } else {
                        this.setState({
                            message: language.cartAddRemove,
                            snackopen: true
                        })

                    }

                } else {
                    this.setState({
                        message: language.anotherBranch,
                        snackopen: true
                    })
                }

            } else {
                this.setState({
                    message: language.cartAddRemove,
                    snackopen: true
                })
            }

        } else { //reduce from cart
            if (this.branchId == this.cartItems.branchId) {
                const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == id);
                if (elementsIndex > -1) {
                    var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty - this.incrementValue);
                    this.cartItems.customerCartItems[elementsIndex] = {
                        product: this.cartItems.customerCartItems[elementsIndex].product,
                        qty: (requiredQty < 0 ? 0 : requiredQty),
                        variantId: this.cartItems.customerCartItems[elementsIndex].variantId,
                    }
                    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));

                    const event = new CustomEvent('productQtyChange');

                    window.dispatchEvent(event)
                    this.setState({
                        ['quantity_' + itemIndex]: this.state['quantity_' + itemIndex] - this.incrementValue,
                    })
                    // Toast.show(language.itemReducedFromCart);


                }

            } else {
                this.setState({
                    message: language.anotherBranch,
                    snackopen: true
                })
            }

        }
    }

    // add to cart
    async addToCart(id, productData, index, e) {
        e.stopPropagation()
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }
        // this.branchId = this.storage.getString('storeId');

        if (productData.isStockAvailable == true) {

            this.cartItemsJson = localStorage.getItem('cartItems');

            if (this.cartItemsJson == null) {
                this.cartItems = {
                    customerCartItems: [
                    ],
                    branchId: parseInt(this.branchId),
                    companyId: language.companyidValue
                }
            } else {
                this.cartItems = JSON.parse(this.cartItemsJson);
            }



            const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == productData.productId)
            if (this.branchId == this.cartItems.branchId) {
                var requiredQty = elementsIndex == -1 ? this.incrementValue : (this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue);

                if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
                    if (elementsIndex == -1) {
                        this.cartItems.customerCartItems = [
                            ...this.cartItems.customerCartItems,
                            {
                                product: productData,
                                qty: this.incrementValue,
                                variantId: null
                            }
                        ]
                    } else {
                        this.cartItems.customerCartItems[elementsIndex] = {
                            product: this.cartItems.customerCartItems[elementsIndex].product,
                            qty: this.cartItems.customerCartItems[elementsIndex].qty + this.incrementValue,
                            variantId: this.cartItems.customerCartItems[elementsIndex].variantId
                        }
                    }
                    this.setState({
                        ['quantity_' + id]: this.state.productData[index].cartQty + this.incrementValue,
                        // spinnerOverlay: false
                    })
                    localStorage.setItem('cartItems', JSON.stringify(this.cartItems));

                    const event = new CustomEvent('productQtyChange');
                    window.dispatchEvent(event)

                    this.setState({
                        message: language.itemAddedTocart,
                        snackopen: true
                    })
                } else {
                    this.setState({
                        message: language.cartAddRemove,
                        snackopen: true
                    })
                }

            } else {
                this.setState({
                    message: language.anotherBranch,
                    snackopen: true
                })
            }




        } else {
            this.setState({
                message: language.cartAddRemove,
                snackopen: true
            })
        }

    }


    // remove the product
    async removeItem(id, productData, index) {

        this.cart = localStorage.getItem('cartItems');
        this.cartItems = JSON.parse(this.cart);
        if (productData.incrementValue == null) {
            this.incrementValue = 1
        } else {
            this.incrementValue = productData.incrementValue
        }

        const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == id);
        this.cartItems.customerCartItems.splice(elementsIndex, 1);
        localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
        if (this.cartItems.customerCartItems.length == 0) {
            localStorage.removeItem('cartItems')
        }
        // this.props.addedItemToCart(1, productData);
        this.setState({
            message: language.removedFromCart,
            snackopen: true
        })
        const event = new CustomEvent('productQtyChange');

        window.dispatchEvent(event)

    }
    render() {
        return (
            <div className='product-list-container' >
                {
                    this.state.data?.map((productData, index) => (
                        <div className='listWrapper' onClick={() => this.props.navigate(`/Productdetails/${productData.productId}`)} key={index}>
                            {

                                productData.oRate == null ?
                                    <div style={{ position: 'absolute', left: 0, top: 15 }}>
                                        {
                                            productData.sRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                                <div style={{}}>
                                                    <p className='offerTag'>{productData.savedPercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    <div style={{ position: 'absolute', left: 0, top: 15 }}>
                                        {
                                            productData.oRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                                <div style={{}}>
                                                    <p className='offerTag'>{productData.savedPercentage}%</p>
                                                </div>
                                                :
                                                null

                                        }
                                    </div>


                            }
                            <div style={{ display: 'flex', flex: 1, }}>
                                <img src={productData.tblProductImages ? productData.tblProductImages[0].image : ''} width={130} height={100} style={{ objectFit: 'cover' }} />
                            </div>
                            <div className='product-details-wrapper'>
                                <div>
                                    <p className='product-name'>{productData.productName}</p>
                                    {productData.regionalLanguage == (null || "") ? null : <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12 }}>{productData.regionalLanguage}</p>}
                                    {
                                        productData.isStockAvailable == false ?
                                            <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red, marginBottom: 5 }}>{language.outStockLabel}</p>
                                            :
                                            <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green, marginBottom: 5 }}>{language.inStockLabel}</p>
                                    }
                                </div>
                                <div>

                                    {

                                        productData.oRate == null ?
                                            <div style={{ alignItems: 'center', }}>
                                                {
                                                    productData.sRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                                        <div style={{ alignItems: 'flex-start' }}>
                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', color: '#BDBDBD', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                {this.state.currencySymbol} {(productData.mrp * productData.incrementValue).toFixed(2)}
                                                            </p>
                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                {this.state.currencySymbol} {(productData.sRate * productData.incrementValue).toFixed(2)}
                                                            </p>

                                                        </div>
                                                        :

                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                            {this.state.currencySymbol} {(productData.sRate * productData.incrementValue).toFixed(2)}
                                                        </p>
                                                }
                                            </div>
                                            :
                                            <div style={{ alignItems: 'center' }}>
                                                {
                                                    productData.oRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                                        <div style={{ alignItems: 'center' }}>
                                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', color: '#BDBDBD', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                {this.state.currencySymbol} {((productData.mrp == null ? productData.sRate : productData.mrp) * productData.incrementValue).toFixed(2)}
                                                            </p>
                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                                {this.state.currencySymbol} {(productData.oRate * productData.incrementValue).toFixed(2)}
                                                            </p>

                                                        </div>
                                                        :
                                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000' }}>
                                                            {this.state.currencySymbol} {(productData.sRate * productData.incrementValue).toFixed(2)}
                                                        </p>

                                                }
                                            </div>


                                    }

                                </div>
                            </div>
                            <div className='cart-edit'>
                                {
                                    language.type == 0 ?

                                        <div style={{ flex: 1, justifyContent: 'center' }}>
                                            {
                                                productData.cartQty > 0 ?
                                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        {
                                                            productData.isStockAvailable == false ?
                                                                null :
                                                                <div style={{ padding: 10 }}>
                                                                    <div className='quantityWrapperProductList'>

                                                                        <div style={{cursor:"pointer"}} onClick={(e) => this.quantityPressed(productData.productId, 'decrement', productData.sRate, productData.productId, productData, (index + 1), e)}>
                                                                            <img src={Minus} size={14} />
                                                                        </div>

                                                                        <div style={{ marginHorizontal: 10, alignItems: 'center', }}>

                                                                            {
                                                                                productData.unit != '-- None --' ?
                                                                                    <p style={{ fontFamily: 'Poppins-Regular', margin: 0, fontSize: 12 }}>{this.state['quantity_' + (productData.productId)]} {productData.unit}</p>
                                                                                    :
                                                                                    <p style={{ fontFamily: 'Poppins-Regular', margin: 0, fontSize: 12 }}>{this.state['quantity_' + (productData.productId)]}</p>
                                                                            }
                                                                        </div>


                                                                        <div style={{cursor:"pointer"}} onClick={(e) => this.quantityPressed(productData.productId, 'increment', productData.sRate, productData.productId, productData, (index + 1), e)}>
                                                                            <img src={Plus} size={14} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                        }
                                                    </div>
                                                    :

                                                    <div style={{ padding: 10 }}>
                                                        <div className='cartWrapperProductList' onClick={(e) => this.addToCart(productData.productId, productData, index, e)}>
                                                            <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, margin: 0 }} > {language.addToCartLabel} </p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    ))
                }

            </div>
        )
    }
}


export default function (props) {
    const navigate = useNavigate()
    return <ProductsListingComponent {...props}
        key={props.page}
        navigate={navigate} />;
}