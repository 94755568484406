import { useContext } from 'react';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import { AuthContext } from '../pages/RootPage';

export default function DetailsHeader() {
	const { toggleDrawer } = useContext(AuthContext);

	return (
		<div>
			<div style={{ marginBlock: 32, marginLeft: 15, paddingVertical: 10 }} onClick={() => toggleDrawer()}>
				<img src={DrawerIcon} style={{ width: 20, height: 20 }} alt=''/>
			</div>
		</div>
	)

}






