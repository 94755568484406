import GoogleMapReact from 'google-map-react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
const MapComponent = ({ currentLatitude, currentLongitude, location }) => {
    let centervar

    const defaultProps = {
        center: {
            lat: currentLatitude,
            lng: currentLongitude
        },
        zoom: 15
    };
    const LocationPin = ({ text }) => {
        return (
            <div className="pin" style={{ height: 300, display: 'flex', }}>
                <LocationOnOutlinedIcon sx={{ color: 'red', }} />
                <div style={{ backgroundColor: 'white', height: 30, width: 100, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display: 'flex' }} onClick={setLocation}>
                    <p style={{ margin: 0, }}>Set Location</p>
                </div>

            </div>
        )
    }


    const setLocation = () => {
        location(centervar ? centervar.lat : currentLatitude, centervar ? centervar.lng : currentLongitude)
    }
    const setchangedLocation = ({ center, zoom, bounds, marginBounds }) => {
        centervar = center
    }
    return (
        <div style={{ height: 500, width: '100%', padding: 10, }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBYvuCIXWbeyOhTRjoxZGd5pYvYK3AaHmA" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onChange={setchangedLocation}

            >
                <div>
                    <LocationPin />
                </div>
            </GoogleMapReact>

        </div>

    )

}

export default MapComponent