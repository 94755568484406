import { useEffect, useState } from 'react';
import * as language from '../constants/languages';
import { useNavigate } from 'react-router-dom';
import Delete from '../assets/svg/close.svg'
export default function DropDownCart({ cart, currencySymbol, isVisible, setVisibility }) {
    const navigate = useNavigate()
    const [cartItems, setcartItems] = useState(cart)
    const [itemCount, setitemCount] = useState()
    const [snackopen, setsnackopen] = useState(false)
    const [message, setmessage] = useState('')
    const [totalValue, settotalValue] = useState(0)
    const [outOfStock, setoutOfStock] = useState(false)

    useEffect(() => {
        getCart()
    }, [cart])

    function getCart() {
        setcartItems(cart)
        reCalculateCartTotal(cart);
    }

    // remove item from cart
    async function removeItem(id, cartItem, e) {
        e.stopPropagation()
        const cart = localStorage.getItem('cartItems')
        const cartitems = JSON.parse(cart);
        const elementsIndex = cartitems.customerCartItems.findIndex(element => element.product.productId == cartItem.product.productId);
        cartitems.customerCartItems.splice(elementsIndex, 1);
        if (cartitems?.customerCartItems?.length == 0) {
            localStorage.removeItem('cartItems')
        }
        else {
            localStorage.setItem('cartItems', JSON.stringify(cartitems));
        }
        setitemCount(cartitems?.customerCartItems?.length)
        reCalculateCartTotal(cartitems);
        setcartItems(cartitems)
        const event = new CustomEvent('productQtyChange');
        window.dispatchEvent(event)
        const removeevent = new CustomEvent('cartitemremoved');
        window.dispatchEvent(removeevent)
        showToastMessage(language.removedFromCart);

    }

    const showToastMessage = (message) => {
        setmessage(message)
        setsnackopen(true)
    }

    async function reCalculateCartTotal(cart) {
        let total = 0;
        setoutOfStock(false)

        // let cart = storage.getString('cartItems');
        if (!cart) { return }
        // let cartItems = JSON.parse(cart);
        let cartItems = cart;

        for (let i = 0; i < cartItems?.customerCartItems?.length; i++) {

            if (cartItems.customerCartItems[i].product.oRate != null) {
                if (cartItems.customerCartItems[i].product.incrementValue == null) {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.oRate)

                } else {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.oRate)
                }
            } else {
                if (cartItems.customerCartItems[i].product.incrementValue == null) {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.sRate)

                } else {
                    total = total + (cartItems.customerCartItems[i].qty * cartItems.customerCartItems[i].product.sRate)
                }
            }
            if (outOfStock == false) {
                if (cartItems.customerCartItems[i].product.isStockAvailable == false) {
                    //out of stock items
                    setoutOfStock(true)


                } else {
                    setoutOfStock(false)

                }
            }

        }
        settotalValue(total)

    }

    return (
        isVisible &&
        <div className='cart-floating-container' onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
            {
                cartItems ?

                    <div className='cart-floating'>
                        {
                            cartItems?.customerCartItems?.map((cartItem, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, paddingBlock: 10, marginBottom: 10, borderBottomWidth: index == cartItems?.customerCartItems?.length - 1 ? 0 : 0.1, borderColor: '#70707030', alignItems: 'center', borderBottomStyle: 'solid',cursor:'pointer' }} onClick={() => navigate(`/Productdetails/${cartItem.product.productId}`)}>
                                    <div style={{ display: 'flex', flex: 1, height: '100%' }}>
                                        <img src={cartItem.product.tblProductImages ? cartItem.product.tblProductImages[0].image : null} width={100} height={100} style={{objectFit:'cover'}}/>
                                    </div>
                                    <div style={{ marginLeft: 15, display: 'flex', flex: 2, flexDirection: 'column', }}>
                                        {
                                            cartItem.product.brand?.brandName &&
                                            <p style={{ fontFamily: 'Poppins-Regular', color: '#9E9E9E', fontSize: 9 }}>{cartItem.product.brand?.brandName}</p>
                                        }
                                        <p style={{ fontFamily: 'Poppins-Regular', marginBottom: 0 }}>{cartItem.product?.productName}</p>
                                        {
                                            cartItem.product.regionalLanguage &&
                                            <p style={{ fontFamily: 'Poppins-Regular', fontSize: 12, marginBottom: 0 }}>{cartItem.product.regionalLanguage == null ? "" : cartItem.product.regionalLanguage}</p>
                                        }
                                        {
                                            cartItem.product.isStockAvailable == false ?
                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red, marginBottom: 0 }}>{language.outStockLabel}</p>
                                                :
                                                <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green, marginBottom: 0 }}>{language.inStockLabel}</p>
                                        }
                                        {
                                            cartItem.product.oRate == null ?
                                                <div style={{ alignItems: 'center', }}>
                                                    {
                                                        cartItem.product.sRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                            <div >
                                                                <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                    {currencySymbol} {(cartItem.product.mrp * cartItem.product.incrementValue).toFixed(2)}
                                                                </p>
                                                                <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                    {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                                </p>

                                                            </div>
                                                            :

                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                            </p>
                                                    }
                                                </div>
                                                :
                                                <div style={{ alignItems: 'center' }}>
                                                    {
                                                        cartItem.product.oRate < (cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) ?
                                                            <div style={{}}>
                                                                <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through', marginRight: 5, fontSize: 12, marginBottom: 0 }}>
                                                                    {currencySymbol} {((cartItem.product.mrp == null ? cartItem.product.sRate : cartItem.product.mrp) * cartItem.product.incrementValue).toFixed(2)}
                                                                </p>
                                                                <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                    {currencySymbol} {(cartItem.product.oRate * cartItem.product.incrementValue).toFixed(2)}
                                                                </p>

                                                            </div>
                                                            :
                                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 16, color: '#FF0000', marginBottom: 0 }}>
                                                                {currencySymbol} {(cartItem.product.sRate * cartItem.product.incrementValue).toFixed(2)}
                                                            </p>

                                                    }
                                                </div>
                                        }
                                        <p style={{margin:0,fontFamily: 'Poppins-Regular', fontSize:12}}>Qty: {cartItem.qty}</p>
                                    </div>
                                    <div style={{ display: 'flex', alignSelf: 'flex-start', marginTop: 15 }}>
                                        <div className='removeButtonWraper' onClick={(e) => removeItem(cartItem.productId, cartItem, e)}>
                                            <img src={Delete} height={20} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            // totalValue > 0 ?
                            <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 55,marginBottom:'1rem', borderBlockWidth: 0.4, borderColor: '#70707045', paddingTop: 15, display: 'flex', borderBlockStyle: 'solid', marginInline: 15 }}>
                                <p style={{ fontFamily: 'Poppins-Regular', fontSize: 18 }}>Total</p>

                                <p style={{ fontFamily: 'Montserrat-Medium', color: '#FF0000', fontSize: 18 }}>{currencySymbol} {totalValue}</p>
                            </div>
                            // : null
                        }
                        <div className='view-cart-button' onClick={()=>navigate('/cart')}>
                            <p style={{ margin: 0, color: '#ffffff', fontFamily:'Poppins-Regular' }}>VIEW CART</p>
                        </div>
                    </div>
                    :
                    <div className='cart-no-item'>
                        <p>{language.noItmsLabe}</p>
                    </div>

            }

        </div>

    )

}