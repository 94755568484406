import { useParams } from "react-router-dom"

export function PendingOrderPage(){
    const data = useParams()
    console.log(data)
    return(
        <div>

        </div>
    )
}