import { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import Close from '../assets/svg/close.svg'

export default function ProductDetailsBrochure({ imagePressed, data, stock }) {

    const [imgIndex, setimgIndex] = useState(0)
    const [isImgVisible, setisImgVisible] = useState(false)
    let mediaQueryList = window.matchMedia("(min-width: 992px)");
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)
    //check if mobile
    function checkDevice(mql) {
        setisLargeDisplay(mql.matches)
    }
    useEffect(() => {
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
            return () => {
                mediaQueryList.removeEventListener("change", checkDevice)
            }
        } else {
            mediaQueryList.addListener(checkDevice)
            return () => {
                mediaQueryList.removeListener(checkDevice)
            }
        }

    }, [])
    return (

        <div>

            <div className='product-details-brochure' onClick={() => setisImgVisible(true)}>
                <TransformWrapper disabled={!isLargeDisplay}>
                    <TransformComponent >
                        <img src={data[imgIndex]?.image} className='product-details-brochure-image' alt='Product Image' />
                    </TransformComponent>
                </TransformWrapper>
            </div>

            <div style={{ display: 'flex', flexGrow: 1, marginTop: 30, marginLeft: 7, overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }} >
                {
                    data.map((item, index) => (

                        <div key={index} >

                            <div style={{ borderRadius: 10, marginInline: 8, borderWidth: 0.4, borderColor: imgIndex == index ? 'black' : '#707070', overflow: 'hidden', padding: 10, borderStyle: 'solid' }} onClick={() => setimgIndex(index)} >

                                <img src={item.image} style={{ width: 80, height: 80, objectFit: 'cover' }} alt='Product image thumbnail' />
                            </div>

                        </div>

                    ))
                }
            </div>
            {!isLargeDisplay &&
                isImgVisible && <div style={{ position: 'fixed', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

                    <TransformWrapper>
                        <TransformComponent>
                            <img src={data[imgIndex]?.image} style={{ width: '100%', height: '100%' }} alt='Product Image' />
                        </TransformComponent>
                    </TransformWrapper>
                    <div style={{ position: 'absolute', top: 15, left: 10, backgroundColor: '#ffffff65', borderRadius: 3 }}>
                        <img src={Close} height={40} onClick={() => setisImgVisible(false)} />
                    </div>
                </div>
            }
        </div>

    )

}