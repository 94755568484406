
import React, { useEffect, useState, } from 'react';
import * as language from '../constants/languages';
import SpinnerLoader from '../components/SpinnerLoader'
import { useNavigate } from 'react-router-dom';

function OfferListComponent({ navigation, data, }) {
    const navigate = useNavigate()

	async function openOfferDetails(item) {

		const cart = localStorage.getItem('cartItems');

		if (item.offerDetails == null) {
			navigate('/Terms&Conditions', { heading: 'Offer Terms & Conditions', state:{offerDetails: item.longDescription, pageId: 3} })
		} else if (item.offerDetails.length == 1) {
			navigate(`/Productdetails/${item.offerDetails[0].productId}`)
			
		} else if (item.offerDetails.length > 1) {
			var offerItems = [];
			for (let i of item.offerDetails) {
				offerItems = [...offerItems, i.product]
			}
			if (cart) {
                navigate('/Products', {state:{

					heading: item.offername, offerId: item.offerId, isOffer: 1, params: {
						identity: 'offer',
					},
				}})
        
				
			} else {
                navigate('/Products', {state:{

					heading: item.offername, offerId: item.offerId, isOffer: 1, params: {
						identity: 'offer',
					},
				}})
			
			}
		} else {
			// console.log(item, 'item');
		}
	}

	


	
	return (

		<div >
			<div style={{ flex: 1, }}>

				{
					data.map((offers, index) => (
						<div key={index} style={{paddingBlock: 10, marginBottom: 10, elevation: 4 }} onClick={() => openOfferDetails(offers)}>
							{
								
									<img src={  offers.image }
										style={{ width: "100%", height: 'auto',borderRadius:10 }}
										 />
									
							}
						</div>
					))
				}

			</div>
		</div>

	)

}


export default OfferListComponent