import QRCode from "react-qr-code";
import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import VerifiedIcon from '../assets/svg/verified.svg'

export default function OrderPlacedComponent({ currencySymbol, navigate, orderPlacedData }) {


    return (
        <div style={{ backgroundColor: 'white', padding: 30, width: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: 3, justifyContent: 'center' }} >
            <img src={VerifiedIcon} />
            <div >
                <h1 style={{ textAlign: 'center', fontFamily: 'Poppins-Regular', fontWeight: 'bold' }}>Your order was placed successfully</h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: 15 }}>
                <QRCode
                    size={128}
                    value={String(orderPlacedData?.orderNo)}
                    style={{ height: 'auto' }}
                />
            </div>
            <div style={{ fontWeight: '500', }}>
                Order No : {String(orderPlacedData?.orderNo)}
            </div>
            <div style={{ fontWeight: '500', color: '#00000075', fontFamily: 'Poppins-Regular', textAlign: 'center' }}>
                Thank you so much for your order
            </div>
            <div style={{ display: 'flex', marginTop: 15, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer' }} onClick={() => navigate('/')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke='#fff'>
                        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                            <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                            <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Home</p>
                </div>
                <div style={{ backgroundColor: '#1d4e4b', padding: 10, borderRadius: 3, color: '#fff', display: 'flex', margin: 5, cursor: 'pointer' }} onClick={() => navigate('/Categories')}>
                    <img src={TabCategory} />
                    <p style={{ marginBlock: 0, marginLeft: 15 }}>Category</p>
                </div>
            </div>
        </div>
    )

}